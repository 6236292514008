import {
    trigger,
    animate,
    transition,
    style,
    query,
    state,
    group,
    animateChild,
    sequence,
    stagger,
    keyframes
 } from '@angular/animations';


export function fadeIn() {
    const optional = {optional: true};

    return [
        query(':enter, :leave', [
            style({
                position: 'absolute',
                width: '100%',
                opacity: 0
            })
        ], optional),
        group([
            query(':leave', [
                animate('800ms ease', style({  opacity: 0 }))
            ], optional),
            query(':enter', [
                animate('800ms ease', style({  opacity: 1 }))
            ])
        ])
    ];
}

export function slideTo(direction) {
    const optional = {optional: true};
    return [
        query(':enter, :leave', [
            style({
                position: 'absolute',
                top: 0,
                [direction]: 0,
                width: '100%'
            })
        ], optional),
        query(':enter', [
            style({ [direction]: '-20%', opacity: 0 })
        ]),
        group([
            query(':leave', [
                animate('600ms ease', style({ [direction]: '20%', opacity: 0 }))
            ], optional),
            query(':enter', [
                animate('600ms ease', style({ [direction]: '0%', opacity: 1 }))
            ])
        ])
    ];
}

export const sliderAnimation = trigger('routeAnimation', [
    transition('* => isSub', slideTo('right')),
    transition('isSub => *', slideTo('left')),
    
    transition('* => isLeft', fadeIn()),
    transition('* => isRight', fadeIn()),
    transition('* => isTop', fadeIn()),

    // transition('isTop => isLeft', slideTo('bottom')),
    // transition('isTop => isRight', slideTo('bottom')),
    
    // transition('isLeft => isTop', slideTo('top')),
    // transition('isRight => isTop', slideTo('top')),

]);


export const loginAnimation = trigger('login-logo-animation', [
    transition(':enter', [
        style({
            opacity: 0,
            marginTop: '50px',
            height: '65%'
        }),
        animate('3500ms ease-out', keyframes([
            style({
                offset: 0.92,
                opacity: 1,
            }),
            style({
                offset: 1,
                marginTop: 0,
                height: '*'
            })
        ])),
    ]),
]);

export const loginInputAnimation = trigger('login-input-animation', [
    transition(':enter', [
        style({
            transform: 'translateY(20px)',
            opacity: 0,
        }),
        animate('4500ms ease-out', keyframes([
            style({
                offset: 0.9,
                
            }),
            style({
                transform: 'translateY(0)',  
                offset: 1,
                opacity: 1,
            })
        ])),
    ]),
]);

export const loginActionsAnimation = trigger('login-actions-animation', [
    transition(':enter', [
        style({
            transform: 'translateY(20px)',
            opacity: 0,
        }),
        animate('5000ms ease-out', keyframes([
            style({
                offset: 0.9,
                
            }),
            style({
                transform: 'translateY(0)',
                offset: 1,
                opacity: 1,
            })
        ])),
    ]),
]);
