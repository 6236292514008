import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  menu = {
    top: [
      {
        name: '',
        items: [
          { name: 'Dashboard', location: '/live', active: true },
          { name: 'Farmers', location: '/farmers', active: false },
          { name: 'Plans', location: '/plans', active: false },
          { name: 'Market', location: '/market', active: false },
          { name: 'Simulator', location: '/simulator', active: false },
        ]
      },
      
    ],
    bottom: [
      {
        name: '',
        items: [
          { name: 'Settings', location: '/settings', active: false },
          { name: 'Sign Out', location: '/sign-out', active: false },
        ]
      },
      
    ]
  }

  constructor(
     private router: Router,
     public authenticationService: AuthenticationService
  ) { }

  managePage() {
    this.menu.top.forEach(element => {
      element.items.forEach(item => {
        item.active = location.href.indexOf(item.location) > -1
      });
    });
  }

  ngOnInit() {
    this.managePage();
  }

  navigate(items, item) {

    if (item.location === '/sign-out') {
      this.authenticationService.signOut();
      return;
    }

    items.forEach(element => {
      element.active = false;      
    });

    item.active = true;

    this.router.navigate([item.location])
  }

}
