

export const environment = {
  production: false,
  api: 'https://velocity-test.bmwgroup.com/',
  inactivity: 30,
  session: {
    tokenId: 'JRRTOLKIEN',
    userId: 'BOOMBAWAKA'
  },
  permissions: {
    sidebar: { permittedRoles: ['SUPER'] },
  }
};
