import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.scss']
})
export class MarketComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  goToChart(key) {
    this.router.navigate([`/market/${key}`])
  }

}
