import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UTILS } from 'src/app/utils/utils';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationRestService {

  ROUTES = {
    LOGIN: 'authentication/login',
  };

  constructor(private $http: HttpClient) { }

  login(username, password) {
    const login = {
      username: username,
      password: password
    };

    const url = UTILS.buildUrl(this.ROUTES.LOGIN);

    debugger;

    return this.$http.post(url, login, { headers: { 'Content-Type': 'application/json' }});
    
  }
}
