import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { loginActionsAnimation, loginAnimation, loginInputAnimation } from '../route-animations';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    loginAnimation,
    loginInputAnimation,
    loginActionsAnimation
  ]
})
export class LoginComponent implements OnInit {

  username = 'neil';
  password = '12345';

  constructor(
    public authenticationService: AuthenticationService, 
    private router: Router,
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
  }

  login() {
    this.authenticationService.login(this.username, this.password).subscribe(response => {
      debugger;
      this.router.navigate(['/live']);
    })
  }

}
