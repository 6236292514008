import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ChartsModule, ThemeService } from 'ng2-charts'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LiveComponent } from './live/live.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FarmerComponent } from './farmer/farmer.component';
import { PlansComponent } from './plans/plans.component';
import { FarmersComponent } from './farmers/farmers.component';
import { MarketComponent } from './market/market.component';
import { SimulatorComponent } from './simulator/simulator.component';
import { PlanComponent } from './plan/plan.component';
import { PriceComponent } from './price/price.component';
import { AuthenticationService } from './services/authentication/authentication.service';
import { ErrorService } from './services/error/error.service';
import { InactivityService } from './services/inactivity/inactivity.service';
import { CookieService } from './services/cookie/cookie.service';
import { ErrorInterceptorService } from './interceptors/error-interceptor.service';
import { HttpInterceptorService } from './interceptors/http-interceptor.service';
import { AuthenticationDirective } from './directives/authentication.directive';
import { SignOutComponent } from './sign-out/sign-out.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    LiveComponent,
    SidebarComponent,
    FarmerComponent,
    PlansComponent,
    FarmersComponent,
    MarketComponent,
    SimulatorComponent,
    PlanComponent,
    PriceComponent,
    AuthenticationDirective,
    SignOutComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ChartsModule
  ],
  providers: [
    ThemeService,
    AuthenticationService,
    ErrorService,
    InactivityService,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: 'en-GB' },
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
  
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
