import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CookieService } from '../cookie/cookie.service';
import { ScatmanService } from '../encryption/scatman.service';
import { AuthenticationRestService } from './authentication.rest.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  currentSession = null;

  authenticationChange: Subject<boolean> = new Subject();
  notAuthenticated: Subject<boolean> = new Subject();
   
  constructor(
    public authenticationRestService: AuthenticationRestService,
    public cookieService: CookieService,
    public scatmanService: ScatmanService,
    private router: Router
    ) { }

  getCurrentUser() {

    debugger;

    const sessionUserEncrypted = this.cookieService.getUserCookie();

    if (!sessionUserEncrypted) {
      return undefined;
    }

    const sessionData = this.scatmanService.decode(sessionUserEncrypted, true);
    const sessionUser = sessionData ? sessionData.user : null;

    this.currentSession = {
      loginTime: sessionData.time,
      user: sessionUser,
    };

    return sessionUser;
  }

  private stripUserFromResponse(response) {

    return {
      username: response.username,
      email: response.email,
      firstName: response.firstName,
      surname: response.surname,
      cellphone: response.cellphone,
      role: response.roles
    };
  }

  private stripTokensFromResponse(response) {

    return {
      token: response.token,
      socketToken: response.socketToken,
      expiry: response.expiry
    };
  }

  private generateSecrets() {
    this.cookieService.initSecretCookie();
  }

  private createAuthCookies(token, user) {

    const tokenEncryption = this.scatmanService.encode({
      loginTime: new Date(),
      tokens: token
    }, true);

    this.cookieService.initGenericCookie(environment.session.tokenId, tokenEncryption);

    const userEncryption = this.scatmanService.encode({
      loginTime: new Date(),
      user: user
    }, true);

    this.cookieService.initGenericCookie(environment.session.userId, userEncryption);

  }

  signOut() {

    debugger;

    this.cookieService.deleteAuthCookies();
    this.cookieService.deleteSecretCookie();
    this.cookieService.deleteSessionCookie();

    this.authenticationChange.next(true);

    this.router.navigate(['/sign-out']);

  }

  login(username, password): Observable<any> { 

    debugger;

    return this.authenticationRestService.login(username, password).pipe(map(((response: any) => {

      debugger;

      const currentUser = this.stripUserFromResponse(response.user);
      const currentTokens = this.stripTokensFromResponse(response.credentials);

      this.generateSecrets();

      this.cookieService.initSessionCookie(currentUser.email);

      this.createAuthCookies(currentTokens, currentUser);
     
      this.authenticationChange.next(true);

      return response;
    }), catchError(error => {
      return of(undefined);
    })));

  }
}
