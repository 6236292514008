import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IUser, SystemRoles } from '../../interfaces/authentication';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from '../../../environments/environment';
import { UTILS } from '../../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  currentRoles: Set<SystemRoles>;

  private readonly PERMISSIONS = environment.permissions;

  constructor(public authenticationService: AuthenticationService) {}

  checkAuthorization(path: any, additional: any): Observable<boolean> {

    if (!path) {
      return;
    }

    const currentUser: IUser = this.authenticationService.getCurrentUser();

    this.currentRoles = new Set(!currentUser ? [SystemRoles.UNAUTHORIZED] : currentUser.role);

    const entry = UTILS.findEntry(this.PERMISSIONS, path);

    if (entry && entry.hasOwnProperty('permittedRoles')) {

      debugger;
      

      const hasPermission = entry.permittedRoles.some(permittedRole => this.currentRoles.has(permittedRole) || permittedRole === 'ALL');

      if (hasPermission) {
        return of(true);
      }

      const readable = path.indexOf('readonly') > -1;
      if (readable) {
        return of (true);
      }

      return of(false);
    }

    return of(false);
  }

  getPermissionBasedOnRetailerAndGroup(vehicle) {
    const vehicleRetailer = vehicle.dealerId;
    const vehicleGroup = vehicle.dealerGroup;

    const user = this.authenticationService.getCurrentUser();

    if (!user) {
      return {
        inRetailer: false,
        inGroup: false
      };
    }

    const userRetailer = user.dealerNumber;
    const userGroup = user.dealerGroup;

    return {
      inRetailer: vehicleRetailer === userRetailer,
      inGroup: vehicleGroup === userGroup
    };

  }
}
