import { Injectable } from '@angular/core';
import * as jsscompress from 'js-string-compression';
import SimpleCrypto from 'simple-crypto-js';
import { environment } from '../../../environments/environment';
import { CookieService } from '../cookie/cookie.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ScatmanService {

  constructor(private cookieService: CookieService, private router: Router) { }

  getBrowser() {
    if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1 ) {
      return 'Opera';
    }

    if (navigator.userAgent.indexOf('Chrome') !== -1 ) {
        return 'Chrome';
    }

    if (navigator.userAgent.indexOf('Safari') !== -1) {
        return 'Safari';
    }

    if (navigator.userAgent.indexOf('Firefox') !== -1 ) {
        return 'Firefox';
    }

    if ((navigator.userAgent.indexOf('MSIE') !== -1 )) {
      return 'IE';
    }

    return 'unknown';
  }

  checkEncryptionVersionForBrowser() {

    const browser = this.getBrowser();

    if (browser === 'Safari') {
      return 'NO_ENCRYPTION';
    }

    return 'FULL_ENCRYPTION';
  }

  decode(value: any, internal = false, secret?): any {

    const version = this.checkEncryptionVersionForBrowser();

    if (!value) {
      return null;
    }

    if (version === 'FULL_ENCRYPTION') {
      const secrets = !secret ? this.cookieService.getSecretsCookie() : secret;

      try {
        const decompressed = new jsscompress.Hauffman().decompress(value);
        const simpleCrypto = new SimpleCrypto(internal ? secrets.internal : secrets.external);
        const decryptedValues = simpleCrypto.decrypt(decompressed) as string;
        return JSON.parse(decryptedValues);

      } catch (error) {
        return null;
      }
    }

    return JSON.parse(value);
  }

  encode(value, internal = false, secret?): string {

    const version = this.checkEncryptionVersionForBrowser();

    if (typeof value !== 'string') {
      value = JSON.stringify(value);
    }

    if (version === 'FULL_ENCRYPTION') {
      const secrets = !secret ? this.cookieService.getSecretsCookie() : secret;

      const simpleCrypto = new SimpleCrypto(internal ? secrets.internal : secrets.external);
      const encryptedValues = simpleCrypto.encrypt(value);
      const compressed = new jsscompress.Hauffman().compress(encryptedValues);

      return compressed;
    }

    return value;
  }
}
