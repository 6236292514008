import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { RoutingService } from './services/routing/routing.service';
import { sliderAnimation } from './route-animations'; 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    sliderAnimation
  ]
})
export class AppComponent {
  title = 'windpomp';

  constructor(private routingService: RoutingService) {
    this.routingService.init();
  }    
  
  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation']
  }
}
