import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-farmers',
  templateUrl: './farmers.component.html',
  styleUrls: ['./farmers.component.scss']
})
export class FarmersComponent implements OnInit {

  farmers = [
    {
      name: 'Koos Du Plessis',
      business: 'Stanford Hill Farm situated near pretoria East',
      size: 1200,
      crops: 1,
      hedged: 0,
      plan: '60 / 40',
      profitPercentage: 10,
      profit: 6000,
      recommendation: {
        type: 'Hedge',
        percentage: 10
      }
    },
    {
      name: 'Abraham Stefanus',
      business: 'Stanford Hill Farm situated near pretoria East',
      size: 1200,
      crops: 1,
      hedged: 0,
      plan: '60 / 40',
      profitPercentage: 10,
      profit: 6000,
      recommendation: {
        type: 'Hedge',
        percentage: 10
      }
    }
  ]

  constructor(private router: Router) { }

  ngOnInit() {
  }

  addFarmer() {
    this.router.navigate(['/farmer/add'])
  }

  deleteFarmer(event) {
    event.stopPropagation()
    this.router.navigate(['/farmer/remove'])
  }

  viewFarmer() {
    this.router.navigate(['/farmer/view'])
  }

}
