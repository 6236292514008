import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../services/error/error.service';
import { CookieService } from '../services/cookie/cookie.service';

interface IError {
  code: number;
  shouldLogout: boolean;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {

  private logoutMessages = ['CodeWeaverClient'];

  constructor(
    public authenticationService: AuthenticationService,
    private router: Router,
    private cookieService: CookieService,
    private errorService: ErrorService
    ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!next.handle(request)) {
      if (request.url.indexOf('quotes/') > -1) {
        this.errorService.signOut();
      }
      return;
    }

    return next.handle(request).pipe(catchError(err => {

      if (request.url.indexOf('/offer/') !== -1) {
        if (err.status === 404) {
          return throwError(err.error.message || err.status);
        }
      }

      return this.handleError(err);
    }));

  }


  handleError(err) {
    return throwError(err);
  }
 

}

