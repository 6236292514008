import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';



enum RuleMeasurable {
  VALUE = 'VALUE',
  PERCENTAGE = 'PERCENTAGE'
}

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit {

  action = '';

  actions = [ 'BUY', 'SELL' ];

  ruleType = [ 'PRICE' ];

  ruleConditions = [
    'ABOVE',
    'BELOW'
  ];

  actionTypes = [
    'BUY',
    'SELL',
    'NONE'
  ]

  months = [
    { name: 'January', selected: false },
    { name: 'February', selected: false },
    { name: 'March', selected: false },
    { name: 'April', selected: false },
    { name: 'May', selected: false },
    { name: 'June', selected: false },
    { name: 'July', selected: false },
    { name: 'August', selected: false },
    { name: 'September', selected: false },
    { name: 'October', selected: false },
    { name: 'November', selected: false },
    { name: 'December', selected: false },
  ];

  triggers = []

  constructor(private router: Router, private route: ActivatedRoute) {

    this.route.paramMap.subscribe((params: any) => {
      this.action = params.params.action;
    });

  }

  ngOnInit() {
  }

  backToPlans() {
    this.router.navigate(['/plans']);
  }

  selectMonth(month) {
    month.selected = !month.selected;

    if (!month.selected) {
      _.remove(this.triggers, item => {
        return item.key === month.name
      });

      return;
    }

    const data = { 
      key: month.name,
      timeFrame: { earliest: '01/03', latest: '31/03', optimal: '15/01' },
      condition: { 
        if: 'PRICE', 
        is: 'ABOVE', 
        whatValue: 3000, 
        by: RuleMeasurable.PERCENTAGE,
        byValue: 12,
        then: 'SELL',
        howMuch: RuleMeasurable.PERCENTAGE,
        howMuchValue: 10
      }
 
    }

    this.triggers.push(data);


    
  }

}
