import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FarmerComponent } from './farmer/farmer.component';
import { FarmersComponent } from './farmers/farmers.component';
import { HomeComponent } from './home/home.component';
import { LiveComponent } from './live/live.component';
import { MarketComponent } from './market/market.component';
import { PlanComponent } from './plan/plan.component';
import { PlansComponent } from './plans/plans.component';
import { PriceComponent } from './price/price.component';
import { SignOutComponent } from './sign-out/sign-out.component';
import { SimulatorComponent } from './simulator/simulator.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: { animation: '' }
  },
  {
    path: 'live',
    component: LiveComponent,
    data: { animation: 'isTop' }
  },
  {
    path: 'farmers',
    component: FarmersComponent,
    data: { animation: 'isRight' }
  },
  {
    path: 'farmer/:action',
    component: FarmerComponent,
    data: { animation: 'isSub' }
  },
  {
    path: 'plans',
    component: PlansComponent,
    data: { animation: 'isLeft' }
  },
  {
    path: 'plan/:action',
    component: PlanComponent,
    data: { animation: 'isSub' }
  },
  {
    path: 'market',
    component: MarketComponent,
    data: { animation: 'isRight' }
  },
  {
    path: 'market/:action',
    component: PriceComponent,
    data: { animation: 'isSub' }
  },
  {
    path: 'simulator',
    component: SimulatorComponent,
    data: { animation: 'isLeft' }
  },
  {
    path: 'sign-out',
    component: SignOutComponent,
    data: { animation: 'isLeft' }
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {useHash: true})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
