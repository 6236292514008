import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  signingOut = false;

  message = '';

  messages = {
    INACTIVITY: 'You have been logged out due to inactivity.',
    ERROR: 'Your session has expired.'
  };

  show = false;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute) { }

  throwError(type) {
    this.showError(type);
  }

  showError(error: any) {
    this.message = this.messages[error];
    this.show = true;
  }

  hideError() {
    this.message = '';
    this.show = false;
    this.signingOut = false;
  }

  signOut() {
    this.signingOut = true;
    this.router.navigate([`../sign-out`], { relativeTo: this.route });
    this.hideError();
  }
}
