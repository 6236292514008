import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { ScatmanService } from '../services/encryption/scatman.service';
import { CookieService } from '../services/cookie/cookie.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(
    public authenticationService: AuthenticationService,
    private cookieService: CookieService,
    private router: Router,
    private scatmanService: ScatmanService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const tokenEncryption = this.cookieService.getTokensCookie();
    const tokenData = this.scatmanService.decode(tokenEncryption, true);

    const accessToken = tokenData ? tokenData.tokens.accessToken : '';
    const codeweaverAccessToken = tokenData ? tokenData.tokens.codeweaversToken : '';

    if (request.url.indexOf('cosySec') > -1) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'blob'
        },
      });

      return next.handle(request);
    }

    request = request.clone({
      setHeaders: {
        'X-CW-AccessToken': codeweaverAccessToken,
        'X-Content-Type-Options': 'nosniff',
        'Cache-Control': 'no-store'
      }
    });

    if (request.url.indexOf('authentication') > -1) {
      return next.handle(request);
    }

    if (request.url.indexOf('tracking') > -1) {
      return next.handle(request);
    }

    if (request.url.indexOf('offer') > -1) {
      return next.handle(request);
    }

    if (!tokenData || !accessToken) {
      return;
    }

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
        'X-Content-Type-Options': 'nosniff',
        'Cache-Control': 'no-store'
      }
    });

    return next.handle(request);
  }

  getAccessToken() {
    const tokenEncryption = this.cookieService.getTokensCookie();
    const tokenData = this.scatmanService.decode(tokenEncryption, true);
    const accessToken = tokenData ? tokenData.tokens.accessToken : '';

    return accessToken;
  }

  getCodeWeaverToken() {
    const tokenEncryption = this.cookieService.getTokensCookie();
    const tokenData = this.scatmanService.decode(tokenEncryption, true);
    return tokenData ? tokenData.tokens.codeweaversToken : '';
  }
}
