import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { PermissionService } from '../services/permission/permission.service';

@Directive({
  selector: '[appAuthentication]'
})
export class AuthenticationDirective implements OnInit {

  @Input('appAuthentication') appCanAccess: any | any[];
  private permission$: Subscription;

  constructor(
    public authenticationService: AuthenticationService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionService) {

      this.authenticationService.authenticationChange.subscribe(response => {
        debugger;
        this.applyPermission();
      });

  }

  private extractPermissions() {

    return {
      permissions: this.appCanAccess.filter(i => typeof(i) === 'string'),
      additionalCondition: this.appCanAccess.find(i => typeof(i) !== 'string'),
    };
  }

  private applyPermission(): void {
    const extractedPermissions = this.extractPermissions();

    this.permission$ = this.permissionService.checkAuthorization(extractedPermissions.permissions, extractedPermissions.additionalCondition)
      .subscribe(authorized => {
        if (authorized) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
  }

  ngOnInit() {
    this.applyPermission();
  }

}
