import { Injectable } from '@angular/core';
import { ErrorService } from '../error/error.service';
import { timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {

  private currentInactiveTime = 0;
  private subscription: any;
  private maxInactiveTime = 30 * 60;
  public remainingTime = 0;

  constructor(private errorService: ErrorService) { }


  init() {

    if (!location.href || location.href.indexOf('#/') === -1) {
      return;
    }

    const url = location.href.split('#/');

    if (url.length < 2) {
      return;
    }

    const params = url[1].split('/');

    if (params.length === 0) {
      return;
    }

    const wholeUrl = `${ params[0] }`;
    const currentUrl = wholeUrl.split('?')[0];

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    const interval = 1000;

    this.subscription = timer(0, interval).subscribe(value => {
      this.currentInactiveTime = value;
      this.remainingTime = this.maxInactiveTime - this.currentInactiveTime;

      if (this.currentInactiveTime === this.maxInactiveTime) {

        this.currentInactiveTime = 0;

        this.errorService.throwError('INACTIVITY');

      }
    });
  }

  resetTimer() {
    this.init();
  }
}
