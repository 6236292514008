import { environment } from '../../environments/environment';

export class UTILS {
    static buildUrl(path) {
        return `${environment.api}${path}`;
    }

    static generateUsername(count = 10) {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';
    
        for (let i = 0; i < count; i++) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
    
        return text.toUpperCase();
    }

    static findEntry(currentObject: any, keys: string[], index = 0) {
        const key = keys[index];
        if (currentObject[key] && index < keys.length - 1) {
          return this.findEntry(currentObject[key], keys, index + 1);
        } else if (currentObject[key] && index === keys.length - 1) {
          return currentObject[key];
        } else {
          return false;
        }
    }

    static splitJsonFromUrl(url): any {

        const result = {};

        url = !url ? location.search : url;

        if (!url) {
            return result;
        }

        const queries = url.split('?');

        if (queries.length === 0) {
            return result;
        }

        const query = queries[1];

        query.split('&').forEach((part) => {
          const item = part.split('=');
          result[item[0]] = decodeURIComponent(item[1]);
        });
        return result;
      }

}
