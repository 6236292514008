import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UTILS } from 'src/app/utils/utils';
import { environment } from '../../../environments/environment';

interface IVelocityCookie {
  username: string;
  consent: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  isConsented = false;

  cookie: IVelocityCookie;

  triggerConsent: Subject<boolean> = new Subject();

  cookieName = 'velocity';

  constructor() {}

  getCookieUser() {
    if (!this.cookie) {
      return 'ANONYMOUS';
    }

    return this.cookie.username;
  }


  initSecretCookie() {
    const data = {
      external: UTILS.generateUsername(26),
      internal: UTILS.generateUsername(26)
    };

    const expires = `expires=0`;
    const cookiePath = '';

    document.cookie = `uniques=${JSON.stringify(data)}; ${expires}${cookiePath}`;
  }

  initSessionCookie(email) {
    const expires = `expires=0`;
    const cookiePath = '';

    document.cookie = `session=${JSON.stringify(email)}; ${expires}${cookiePath}`;

    const cookie = this.getSessionCookie();
    return cookie;
  }

  initGenericCookie(name, data, isJSON = true, expirationTime = 0, path = '') {
    const expires = `expires=${expirationTime}`;
    const cookiePath = '';

    const stringifiedData = isJSON ? JSON.stringify(data).split(';').join('{semicolon}') : data;

    document.cookie = `${name}=${stringifiedData}; ${expires}${cookiePath}`;

    const cookie = this.getGenericCookieByName(name, isJSON);
    return cookie;
  }

  deleteAuthCookies() {
    this.deleteCookieByName(environment.session.tokenId);
    this.deleteCookieByName(environment.session.userId);
  }

  private getGenericCookieByName(name, isJSON) {

    const cookies = document.cookie.split(';');

    const cookie =  cookies.filter(i => {
      const value = i.replace(/^\s+/g, '').replace(' ', '');
      if (value.indexOf(`${name}=`) === 0) {
        return value;
      }
    });

    if (cookie.length > 0) {
      cookie[0] = cookie[0].split('{semicolon}').join(';');
      return this.parseCookie(cookie[0], name, isJSON);
    }
    return undefined;
  }

  getTokensCookie() {

    const tokenCookie = this.getGenericCookieByName(environment.session.tokenId, true);
    return  tokenCookie;
  }

  getUserCookie() {

    const userCookie = this.getGenericCookieByName(environment.session.userId, true);
    return userCookie;
  }

  getSessionCookie() {
    return this.getCookieByName('session', true);
  }

  deleteCookieByName(name) {
    const date = new Date(1985, 4, 8);

    const expires = `expires=${ date.toUTCString() }`;
    const cookiePath = '';

    document.cookie = `${name}=; ${expires}${cookiePath}`;
  }

  deleteSessionCookie() {
    const date = new Date(1985, 4, 8);

    const expires = `expires=${ date.toUTCString() }`;
    const cookiePath = '';

    document.cookie = `session=; ${expires}${cookiePath}`;
  }

  deleteSecretCookie() {
    const date = new Date(1985, 4, 8);

    const expires = `expires=${ date.toUTCString() }`;
    const cookiePath = '';

    document.cookie = `uniques=; ${expires}${cookiePath}`;
  }

  getSecretsCookie() {
    return this.getCookieByName('uniques', true);
  }

  private setCookie(value, expirationDays = 90, path = 'FLP') {
    const date = new Date();
    date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);

    const expires = `expires=${ date.toUTCString() }`;
    const cookiePath = path ? `; path=${path}` : '';

    document.cookie = `${this.cookieName}=${JSON.stringify(value)}; ${expires}${cookiePath}`;
  }

  private getCookieByName(name = 'velocity', isJSON) {
    const cookies = document.cookie.split(';');

    const cookie =  cookies.filter(i => {
      const value = i.replace(/^\s+/g, '').replace(' ', '');
      if (value.indexOf(`${name}=`) === 0) {
        return value;
      }
    });

    if (cookie.length > 0) {
      return this.parseCookie(cookie[0], name, isJSON);
    }

    return undefined;

  }

  private parseCookie(cookie, name, isJSON) {
    const data = cookie.trim().replace(`${name}=`, '');

    if (!isJSON) {
      return data;
    }

    try {
      return JSON.parse(data);
    } catch (e) {
      return data;
    }

  }


}
