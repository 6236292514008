import { HostListener, Injectable } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { InactivityService } from '../inactivity/inactivity.service';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  loading = false;

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  @HostListener('mousemove', ['$event'])
  resetTimer() {
    this.inactivityService.resetTimer();
  }

  constructor(
    private router: Router,
    private inactivityService: InactivityService,
    private authenticationService: AuthenticationService
    ) {
 
    }

    init() {
      this.router.events.subscribe((event: RouterEvent) => {
        this.navigationInterceptor(event)
      });
    }

    private navigationInterceptor(event: RouterEvent) {

      const user = this.authenticationService.getCurrentUser();

      if (event instanceof NavigationStart) {
        if (event.url === '/' && user) {
          this.router.navigate(['/live']);
          return;
        }

        this.startLoading();
      }
  
      if (event instanceof NavigationEnd) {
        this.endLoading();
      }
  
      if (event instanceof NavigationCancel) {
        this.endLoading();
      }
  
      if (event instanceof NavigationError) {
        this.endLoading();
      }
    }
  
    private startLoading() {
      this.loading = true;
    }
  
    private endLoading() {
      setTimeout(() => {
        this.loading = false;
        this.inactivityService.resetTimer();
      }, 1000);
    }
}
