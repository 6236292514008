import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-farmer',
  templateUrl: './farmer.component.html',
  styleUrls: ['./farmer.component.scss']
})
export class FarmerComponent implements OnInit {

  action = '';

  crops = [
    { name: 'CORN', selected: false, lgo: undefined, percentage: undefined, hectares: undefined, variable: undefined, fixed: undefined, deliver: 'SILO' },
    { name: 'SUNFLOWER', selected: false, lgo: undefined, percentage: undefined, hectares: undefined, variable: undefined, fixed: undefined, deliver: 'SILO' },
    { name: 'WHEAT', selected: false,lgo: undefined, percentage: undefined, hectares: undefined, variable: undefined, fixed: undefined, deliver: 'SILO'  },
    { name: 'SOYA', selected: false, lgo: undefined, percentage: undefined, hectares: undefined, variable: undefined, fixed: undefined, deliver: 'SILO' },
    { name: 'SORGHUM', selected: false, lgo: undefined, percentage: undefined, hectares: undefined, variable: undefined, fixed: undefined, deliver: 'SILO' },
    { name: 'BARLEY', selected: false, lgo: undefined, percentage: undefined, hectares: undefined, variable: undefined, fixed: undefined, deliver: 'SILO' },
    { name: 'HOBS', selected: false, lgo: undefined, percentage: undefined, hectares: undefined, variable: undefined, fixed: undefined, deliver: 'SILO' },
    { name: 'PEANUTS', selected: false, lgo: undefined, percentage: undefined, hectares: undefined, variable: undefined, fixed: undefined, deliver: 'SILO' }
  ];

  farmersCrops = [];

  constructor(private router: Router, private route: ActivatedRoute) {

    this.route.paramMap.subscribe((params: any) => {
      this.action = params.params.action;
    });

  }

  ngOnInit() {
  }

  selectCrop(crop) {
    crop.selected = !crop.selected;

    if (crop.selected) {
      this.farmersCrops.push(crop);
    }
  }

  backToFarmers() {
    this.router.navigate(['/farmers'])
  }

  create() {
    
  }

}
